import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop.js';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Loader from './components/Loader.js';

import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore, createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import fbConfig from './firebase.js';
import firebase from 'firebase/app';

const store = createStore(rootReducer, 
	compose (
		applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
		reduxFirestore(fbConfig)
	)
);

const fbaseProps = {
	firebase,
	config: fbConfig,
	dispatch: store.dispatch,
	createFirestoreInstance
};

ReactDOM.render(
  <React.StrictMode>
  <Suspense fallback={<Loader />}>
  <BrowserRouter>
  <ScrollToTop>
    <Provider store={store}>
    	<ReactReduxFirebaseProvider {...fbaseProps}>
    		<App />
    	</ReactReduxFirebaseProvider>
    </Provider>
  </ScrollToTop>
  </BrowserRouter>
  </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
