import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import {Link} from 'react-router-dom';

const Navbar = () => {

	const [user, setUser] = useState(false);
	useEffect(() => {
	  const user = firebase.auth().currentUser;
	  const authObserver = firebase.auth().onAuthStateChanged((user) => {
	    setUser(user);
	  });
	  return authObserver;
	});

	if (user) {
	  return (
		<div className="navigation-wrap start-header start-style">
		    <div className="container">
		      <div className="row">
		        <div className="col-12">
		          <nav className="navbar navbar-expand-md">
		            <Link className="navbar-brand" to="/" target="_blank"><img src={require('../assets/logo/mountstribe-white.svg')} alt="Mountstribe"/></Link>
		            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		              <span className="navbar-toggler-icon"></span>
		            </button>		            
		            <div className="collapse navbar-collapse" id="navbarSupportedContent">
		              <ul className="navbar-nav ml-auto py-4 py-md-0">
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
		                  <Link className="nav-link" to="/"><span className="animated fadeIn" style={{animationDelay: '0.30s'}}>Home</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/about-us"><span className="animated fadeIn" style={{animationDelay: '0.35s'}}>About Us</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/store"><span className="animated fadeIn" style={{animationDelay: '0.39s'}}>Store</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/blogs"><span className="animated fadeIn" style={{animationDelay: '0.43s'}}>Blogs</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/contact"><span className="animated fadeIn" style={{animationDelay: '0.46s'}}>Contact</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
							<Link className="nav-link" to="/sign-in"><span className="animated fadeIn" style={{animationDelay: '0.50s'}}>Account</span></Link>
		                </li>
		              </ul>
		            </div>
		          </nav>    
		        </div>
		      </div>
		    </div>
	  	</div>
	  );
	} else {
		return (
		<div className="navigation-wrap start-header start-style">
		    <div className="container">
		      <div className="row">
		        <div className="col-12">
		          <nav className="navbar navbar-expand-md">
		            <Link className="navbar-brand" to="/" target="_blank"><img src={require('../assets/logo/mountstribe-white.svg')} alt="Mountstribe"/></Link>
		            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		              <span className="navbar-toggler-icon"></span>
		            </button>		            
		            <div className="collapse navbar-collapse" id="navbarSupportedContent">
		              <ul className="navbar-nav ml-auto py-4 py-md-0">
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
		                  <Link className="nav-link" to="/"><span className="animated fadeIn" style={{animationDelay: '0.30s'}}>Home</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/about-us"><span className="animated fadeIn" style={{animationDelay: '0.35s'}}>About Us</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/store"><span className="animated fadeIn" style={{animationDelay: '0.39s'}}>Store</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/blogs"><span className="animated fadeIn" style={{animationDelay: '0.43s'}}>Blogs</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
		                  <Link className="nav-link" to="/contact"><span className="animated fadeIn" style={{animationDelay: '0.46s'}}>Contact</span></Link>
		                </li>
		                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
							<Link className="nav-link" to="/sign-in"><span className="animated fadeIn" style={{animationDelay: '0.50s'}}>Sign-in</span></Link>
		                </li>
		              </ul>
		            </div>
		          </nav>    
		        </div>
		      </div>
		    </div>
	  	</div>
	  );
	}
};

export default Navbar;