import React from 'react';

const Loader = () => {
	return (
		<div className="container page-loader">
	        <div className="spinner-border" role="status">
	          <span className="sr-only">Loading...</span>
	        </div>
	    </div>
	);
};

export default Loader;