import $ from 'jquery';

(function($) {
	//"use strict";

	$(function() {
		var header = $(".start-style");
		$(window).scroll(function() {    
			var scroll = $(window).scrollTop();
		
			if (scroll >= 30) {
				header.removeClass('start-style').addClass("scroll-on navigation-scroll");
			} else if (scroll < 30) {
				header.removeClass("scroll-on navigation-scroll").addClass('start-style');
			}
		});
	});		
		
	//Animation
	
	$(document).ready(function() {
        // Navbar JS
        $('body.hero-anime').removeClass('hero-anime');
      });

	//Menu On Hover
		
	$('body').on('mouseenter mouseleave','.nav-item',function(e){
			if ($(window).width() > 750) {
				var _d=$(e.target).closest('.nav-item');_d.addClass('show');
				setTimeout(function(){
				_d[_d.is(':hover')?'addClass':'removeClass']('show');
				},1);
			}
	});	 
	
  })($);