import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';

const firebaseConfig = {

  apiKey: "AIzaSyCjQq_1P21vBm0OTsma8p3lWCqZ7ugTVe4",
  authDomain: "mountstribe-web.firebaseapp.com",
  databaseURL: "https://mountstribe-web.firebaseio.com",
  projectId: "mountstribe-web",
  storageBucket: "mountstribe-web.appspot.com",
  messagingSenderId: "80875004835",
  appId: "1:80875004835:web:dcf17955c9ad5f51a9f016",
  measurementId: "G-4G313HKQ43"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.analytics();

//export const blogDatabase = firebase.database().ref('/blog/posts');
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;