import blogReducer from './blogReducer';
import productReducer from './productReducer';
import productCategoriesReducer from './productCategoriesReducer';
import featuredBlogsReducer from './featuredBlogsReducer';
import subscriptionReducer from './subscriptionReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';

const rootReducer = combineReducers ({
	blog: blogReducer,
	product: productReducer,
	productCategory: productCategoriesReducer,
	featuredBlogs: featuredBlogsReducer,
	subcriptions: subscriptionReducer,
	firestore: firestoreReducer
});

export default rootReducer;