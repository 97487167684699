import React from 'react';
import firebase from 'firebase';
import {Route, Redirect} from 'react-router-dom';
import cookie from 'react-cookies';

export const ProtectedRoute = ({component: Component, ...rest}) => {
	return(
		<Route
		  {...rest}
		  render={props => {
		  	if (firebase.auth().currentUser) {
		  		if (cookie.load('user_subscribe')) {
				}else{
					props.history.push("/store");
				}
				return (
					<Component {...rest.props} />
				)
			}
			else {
				cookie.save('redirection', rest.location, { path: '/' });
				return(
					<Redirect
						to={{
							pathname: '/sign-in'
						}}
					/>
				);
			}
		  }}
		/>
	);
};

