import React from 'react';
import { Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import './assets/css/navbarStyle.css';

import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';

import { ProtectedRoute } from './auth/protected.route.js';

import './assets/js/styles.js';

const Home = React.lazy(() => import('./pages/Home.js'));
const AboutUs = React.lazy(() => import('./pages/AboutUs.js'));
const Contact = React.lazy(() => import('./pages/Contact.js'));
const Blogs = React.lazy(() => import('./pages/Blogs.js'));
const Store = React.lazy(() => import('./pages/Store.js'));
const BlogPost = React.lazy(() => import('./components/BlogPost.js'));
const Subscribe = React.lazy(() => import('./components/Subscribe.js'));
const SignIn = React.lazy(() => import('./components/SignIn.js'));
const Checkout = React.lazy(() => import('./components/Checkout.js'));
const SubscriptionStatus = React.lazy(() => import('./components/SubscriptionStatus.js'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy.js'));
const TermsAndConditions = React.lazy(() => import('./components/TermsAndConditions.js'));
const Policies = React.lazy(() => import('./components/Policies.js'));

const App = (props) => {

	  return (
	  	<div className="wrapper-div">
		    <div className="container-fluid hero-anime header-container">
		    
		      <Navbar />
		      <Switch>
			      <Route exact path='/' render={() => (
			      	<Home />
			      )} />

			      <Route path='/about-us' component={AboutUs} />

			      <Route path='/contact' render={() => (
			      	<Contact />
			      )} />

			      <Route exact path='/blogs' render={() => (
			      	<Blogs />
			      )} />

			      <Route exact path='/blogs/:id' component={BlogPost} />

			      <Route exact path='/store' component={Store} />
			      <Route exact path='/store/:product_subscription' component={Subscribe}/>

			      <ProtectedRoute
			      	exact path='/store/:product_subscription/checkout'
			      	component={Checkout}
			      />

			      <Route exact path='/store/subscription/status/:userid/:orderid' component={SubscriptionStatus} />

			      <Route exact path='/sign-in' component={SignIn} />

			      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
			      <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
			      <Route exact path='/policies' component={Policies} />

		      </Switch>

		      <Footer />
		    </div>
	    </div>
	  );
};

export default App;
